.r-logo {
    width: 35%;
}

.bimg {
    width: 80%;
}

.rdbimg {
    height: 50%;
}


body {
    padding-top: 130px;
    /* Adjust this value based on the combined height of your Navbar and Navheader */
}


.fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030;
    /* Ensure it is above other elements */
}





/* General Styles */
body {
    font-family: 'Arial', sans-serif;
}

.cd-hero {
    background-color: #f9f9f9;
    padding: 20px 0;
}

.cd-hero-slider {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.cd-hero-slider li {
    display: none;
}

.cd-hero-slider .selected {
    display: block;
}

/* Heading Styles */
.heading {
    text-align: center;
    /* margin-bottom: 20px; */
}

.heading h1 {
    font-size: 36px;
    color: #333;
}

.heading span {
    font-size: 18px;
    color: #777;
}

.heading p {
    margin: 0;
}

/* Form Container Styles */
.cd-full-width {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.first-slide {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.container {
    /* max-width: 800px; */
    margin: 0 auto;
}

.row-custom {
    margin-bottom: 20px;
}

/* Form Styles */
.login-content {
    padding: 20px;
    align-content: center;
}

.custom-label-login {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.form-custom {
    width: 100%;

}

.size {
    margin-bottom: 15px;
}

.custom-label {
    text-align: right;
    font-size: 16px;
    color: #333;
    padding-right: 10px;
}

.primary-button {
    text-align: center;
    margin-top: 20px;
}

.primary-button .btn {
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.primary-button .btn:hover {
    background-color: #218838;
}

.primary-button .btn-style {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
}

.primary-button .btn-style:hover {
    background-color: #0069d9;
}




.form-field {
    margin-bottom: 30px !important;

}



/* NAVHEADER STYLING START */

.navbar-custom {
    top: 40px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed !important;
    width: 100%;
    z-index: 1050;
    display: flex;
    align-items: center;
}

/* Ensure navbar items stay aligned */
.navbar-nav {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    /* Adjust spacing */
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
}

/* Ensure text and other nav items are aligned */
.navbar-text {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-left: 10px;
}

/* Keep the cart and user icon aligned */
.navbar-user-info {
    display: flex;
    align-items: center;
    gap: 15px;
    /* Adjust spacing */
}

/* Fix dropdown menu positioning */
.dropdown-menu {
    display: block;
    display: flex;
    position: absolute;
    left: auto;
    right: 0;
}

/* Dropdown menu positioning */
.nav-icon .dropdown-menu.show {
    position: absolute;
    top: 25px;
    transform: translateX(-50%);
    background-color: white;
    text-align: center;
    z-index: 1000;
}

/* Navbar text style */
.navbar-text {
    font-weight: bold;
    margin-right: 15px;
}

/* Navbar dropdown items */
.dropdown-item {
    color: green;
    background-color: white;
}

.dropdown-item:hover {
    color: purple;
    background-color: white;
}

/* NAVHEADER STYLING END */



/* LOGIN page styling start */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* min-height: 100vh; */
    background-color: #f5f5f5;
    padding: 20px;
    /* margin-top: 30px; */
}

/* Header styles */
.login-header {
    text-align: center;
    margin-bottom: 20px;
}

.login-header h1 {
    font-size: 2rem;
    margin-bottom: 5px;
}

.login-header span {
    font-size: 1rem;
    color: #555;
}

/* Form container */
.login-form-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

/* Form title */
.login-title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: bold;
}

/* Form group */
.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;

}

/* Input fields */
.input-field {
    width: 100%;
}

.alert-field {
    margin-top: 5px !important;
    width: 100%;
}

/* Button styles */

.login-btn {
    width: 50%;
    transition: all 0.3s ease !important;
    display: block;
    margin: 0 auto;
}

.login-btn:hover {
    background-color: #ff5722 !important;
    transform: scale(1.1) !important;
}

/* Registration link */
.register-link {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #0072ff;
    font-weight: bold;
    margin-top: 10px;
}

.register-link:hover {
    text-decoration: underline;
}

/* LOGIN page styling end */



/* HOME page styling start */

/* Hero Section */
.hero-section {
    background-color: #ffA500;
    color: #fff;
    text-align: center;
    padding: 64px 24px;
    border-radius: 8px;
    margin-bottom: 48px;
    margin-top: 50px;
}

.hero-title {
    font-weight: bold;
}

.hero-subtitle {
    margin-top: 16px;
}

.hero-button-container {
    display: flex;
    justify-content: center;
    margin-top: 32px;
}

.hero-link {
    text-decoration: none;
}

.hero-button {
    transition: all 0.3s ease !important;
}

.hero-button:hover {
    background-color: #ff5722 !important;
    transform: scale(1.1) !important;
}

/* Steps Section */
.steps-container {
    margin-top: 24px;
    text-align: center;
}

.step-card {
    text-align: center;
    padding: 24px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 200px;
    margin: 0 auto;
}

.step-icon {
    color: #3f51b5;
}

.step-title {
    margin-top: 16px;
}

.step-description {
    margin-top: 8px;
}

/* Benefits Section */
.benefits-container {
    margin-top: 24px;
}

.benefit-card {
    border-radius: 8px;
}

.benefit-image {
    border-radius: 8px;
}

.benefit-description {
    margin-top: 8px;
}

/* FAQ Section */
.faq-container {
    margin-top: 24px;
}

.faq-item {
    margin-top: 16px;
}

.faq-answer {
    margin-top: 8px;
}

/* Hero Section */
.hero-section {
    text-align: center;
    padding: 2rem 0;
    margin-top: 2rem;
    /* Adjust as needed */
}

/* Steps Section */
.steps-container {
    margin-top: 2rem;
}

/* Benefits Section */
.benefits-container {
    margin-top: 2rem;
}

/* FAQ Section */
.faq-container {
    margin-top: 2rem;
}

/* Footer Section */
.footer {
    margin-top: 2rem;
    text-align: center;
    padding: 1rem 0;
}


/* Footer */
.footer {
    text-align: center;
    margin-top: 48px;
    padding: 24px 0;
    border-top: 1px solid #ccc;
}

/* HOME page styling end */


/* PRODUCT DASHBOARD PAGE STYLING START */

/* Container */
.product-dashboard-container {
    margin-top: 2rem;
}

/* Centered Loader */
.centered-loader {
    display: block;
    margin: 2rem auto;
}

/* Button Container */
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

/* Add Product Button */
.add-product-button {
    transition: all 0.3s ease;
}

.add-product-button:hover {
    background-color: #ff5722;
    transform: scale(1.1);
}

/* Remove default link underline */
.no-text-decoration {
    text-decoration: none;
}


/* PRODUCT DASHBOARD PAGE STYLING END */


/* PRODUCT ADDITION PAGE STYLING START */

.file-input {
    color: white;
    margin-bottom: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    display: block;
}

.selected-file-text {
    margin-top: 5px;
    color: green;
}

.primary-button .custom-button .MuiButton-root {
    transition: all 0.3s ease;
}

.primary-button .custom-button .MuiButton-root:hover {
    background-color: #ff5722;
    transform: scale(1.1);
}

/* PRODUCT ADDITION PAGE STYLING END */


/* PRODUCT REGSTRATION DETAILS PAGE STYLING START */

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 16px; /* Equivalent to mt: 4 */
}

.next-button {
    transition: all 0.3s ease !important; /* Smooth hover effect */
}

.next-button:hover {
    background-color: #ff5722 !important; /* Color change on hover */
    transform: scale(1.1) !important; /* Button scales on hover */
}

/* PRODUCT REGSTRATION DETAILS PAGE STYLING END */



/* REGISTER PAGE STYLING START */

.custom-label-login {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.form-custom {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.primary-button {
    text-align: center;
    margin-top: 20px;
}

.custom-btn {
    transition: all 0.3s ease;
}

.custom-btn:hover {
    background-color: #ff5722;
    transform: scale(1.1);
}

.login-link {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    display: block;
    text-align: center;
    margin-top: 10px;
}


/* REGISTER PAGE STYLING END */




/* NAVHEADER MEDIA PAGE START 320 */

@media only screen and (max-width: 320px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-text, .user-icon, .dropdown {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .mobile-menu .dropdown-menu {
        position: relative;
        width: 100px;
        text-align: center;
        background: white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
        z-index: 1050 !important;
    }

    .navbar-nav-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center !important;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100% !important;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
        max-height: 200px;
        overflow-y: auto;
        background: white;
        text-align: center;
        z-index: 1000;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 320 */


/* NAVHEADER MEDIA PAGE START 480 */

@media only screen and (max-width: 480px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-text, .user-icon, .dropdown {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .mobile-menu .dropdown-menu {
        position: relative;
        width: 100px;
        text-align: center;
        background: white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
        z-index: 1050 !important;
    }

    .navbar-nav-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center !important;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100% !important;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
        max-height: 200px;
        overflow-y: auto;
        background: white;
        text-align: center;
        z-index: 1000;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 480 */

/* NAVHEADER MEDIA PAGE START 576 */

@media only screen and (max-width: 576px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-text, .user-icon, .dropdown {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .mobile-menu .dropdown-menu {
        position: relative;
        width: 100px;
        text-align: center;
        background: white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
        z-index: 1050 !important;
    }

    .navbar-nav-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center !important;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100% !important;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
        max-height: 200px;
        overflow-y: auto;
        background: white;
        text-align: center;
        z-index: 1000;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 576 */


/* NAVHEADER MEDIA PAGE START 768 */
@media (max-width: 768px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-text, .user-icon, .dropdown {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .mobile-menu .dropdown-menu {
        position: relative;
        width: 100px;
        text-align: center;
        background: white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
        z-index: 1050 !important;
    }

    .navbar-nav-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center !important;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100% !important;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
        max-height: 200px;
        overflow-y: auto;
        background: white;
        text-align: center;
        z-index: 1000;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 768 */

/* NAVHEADER MEDIA PAGE START 920 */

@media only screen and (max-width: 920px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-text, .user-icon, .dropdown {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .mobile-menu .dropdown-menu {
        position: relative;
        width: 100px;
        text-align: center;
        background: white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
        z-index: 1050 !important;
    }

    .navbar-nav-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center !important;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100% !important;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
        max-height: 200px;
        overflow-y: auto;
        background: white;
        text-align: center;
        z-index: 1000;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 920 */


/* NAVHEADER MEDIA PAGE START 1024 */

@media only screen and (max-width: 1024px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .navbar-brand {
        flex: 1;
    }

    .navbar-toggler {
        order: 2;
    }

    .navbar-collapse {
        order: 3;
        flex: 2;
        justify-content: flex-end;
        background: white;
    }

    .navbar-nav-container {
        display: flex;
        align-items: right;
        flex-grow: 1;
        justify-content: center;
        justify-content: right;
    }

    .navbar-nav {
        display: flex-end;
        flex-wrap: wrap;
        align-items: center;
        justify-content: right;
        gap: 10px;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .nav-item {
        list-style: none;
        white-space: nowrap;
    }


    .navbar-user-info {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .nav-icon {
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute;
        right: 0;
        top: 100%;
    }

    .nav-icon .dropdown-menu.show {
        position: absolute;
        top: 2px;
        transform: translateX(-50%);
        background-color: white;
        text-align: center;
        z-index: 1000;
    }
}

/* NAVHEADER MEDIA PAGE END 1024 */


/* HOME MEDIA PAGE START 320 */

@media (max-width: 320px) {
    .hero-title {
        font-size: 2rem !important;
        /* Decreased font size */
    }

    .hero-subtitle {
        margin-top: 5px !important;
        font-size: 1rem !important;
    }

    .hero-button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    /* Steps Section */
    .step-icon {
        font-size: 2.5rem;
    }

    .step-title {
        font-size: 1.3rem;
    }

    .step-description {
        font-size: 0.9rem;
    }

    /* Benefits Section */
    .benefit-title {
        font-size: 1.3rem;
    }

    .benefit-description {
        font-size: 0.9rem;
    }

    /* FAQ Section */
    .faq-answer {
        font-size: 0.9rem;
    }

    /* Footer */
    .footer {
        font-size: 0.8rem;
    }
}

/* HOME MEDIA PAGE END 320 */



/* HOME MEDIA PAGE START 480 */

@media (max-width: 480px) {
    .hero-title {
        font-size: 2rem !important;
        /* Decreased font size */
    }

    .hero-subtitle {
        margin-top: 5px !important;
        font-size: 1rem !important;
    }

    .hero-button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    /* Steps Section */
    .step-icon {
        font-size: 2.5rem;
    }

    .step-title {
        font-size: 1.3rem;
    }

    .step-description {
        font-size: 0.9rem;
    }

    /* Benefits Section */
    .benefit-title {
        font-size: 1.3rem;
    }

    .benefit-description {
        font-size: 0.9rem;
    }

    /* FAQ Section */
    .faq-answer {
        font-size: 0.9rem;
    }

    /* Footer */
    .footer {
        font-size: 0.8rem;
    }
}

/* HOME MEDIA PAGE END 480 */


/* HOME MEDIA PAGE START 576 */

@media (max-width: 576px) {
    .hero-title {
        font-size: 2rem !important;
        /* Decreased font size */
    }

    .hero-subtitle {
        margin-top: 5px !important;
        font-size: 1rem !important;
    }

    .hero-button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    /* Steps Section */
    .step-icon {
        font-size: 2.5rem;
    }

    .step-title {
        font-size: 1.3rem;
    }

    .step-description {
        font-size: 0.9rem;
    }

    /* Benefits Section */
    .benefit-title {
        font-size: 1.3rem;
    }

    .benefit-description {
        font-size: 0.9rem;
    }

    /* FAQ Section */
    .faq-answer {
        font-size: 0.9rem;
    }

    /* Footer */
    .footer {
        font-size: 0.8rem;
    }
}

/* HOME MEDIA PAGE END 576 */



/* HOME MEDIA PAGE START 768 */

@media (max-width: 768px) {
    .hero-title {
        font-size: 2rem !important;
        /* Decreased font size */
    }

    .hero-subtitle {
        margin-top: 5px !important;
        font-size: 1rem !important;
    }

    .hero-button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }

    /* Steps Section */
    .step-icon {
        font-size: 2.5rem;
    }

    .step-title {
        font-size: 1.3rem;
    }

    .step-description {
        font-size: 0.9rem;
    }

    /* Benefits Section */
    .benefit-title {
        font-size: 1.3rem;
    }

    .benefit-description {
        font-size: 0.9rem;
    }

    /* FAQ Section */
    .faq-answer {
        font-size: 0.9rem;
    }

    /* Footer */
    .footer {
        font-size: 0.8rem;
    }
}

/* HOME MEDIA PAGE END 768 */


/* PRODUCT DASHBOARD PAGE STYLING START 320 */

@media screen and (max-width: 320px) {

    /* Adjust container padding */
    .product-dashboard-container {
        padding: 0.5rem;
    }

    /* Reduce Typography size */
    .product-dashboard-container h4 {
        font-size: 1.2rem;
    }

    /* Make table responsive */
    .table-container {
        overflow-x: auto;
        display: block;
    }

    .table-container table {
        width: 100%;
        font-size: 12px;
    }

    .table-container th,
    .table-container td {
        padding: 4px;
    }

    /* Stack table cells vertically */
    .table-container thead {
        display: none;
    }

    .table-container tbody,
    .table-container tr {
        display: block;
        width: 100%;
    }

    .table-container tr {
        margin-bottom: 10px;
        border: 1px solid #ddd;
        padding: 10px;
    }

    .table-container td {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        padding: 5px;
    }

    .table-container td::before {
        content: attr(data-label);
        font-weight: bold;
        text-transform: capitalize;
    }

    /* Center align loader */
    .centered-loader {
        margin: 1rem auto;
        width: 50px !important;
        height: 50px !important;
    }

    /* Button styles */
    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .add-product-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

/* PRODUCT DASHBOARD PAGE STYLING END 320*/



/* PRODUCT DASHBOARD PAGE STYLING START 480 */

@media screen and (max-width: 480px) {

    /* Adjust container padding */
    .product-dashboard-container {
        padding: 0.5rem;
    }

    /* Reduce Typography size */
    .product-dashboard-container h4 {
        font-size: 1.2rem;
    }

    /* Make table responsive */
    .table-container {
        overflow-x: auto;
        display: block;
    }

    .table-container table {
        width: 100%;
        font-size: 12px;
    }

    .table-container th,
    .table-container td {
        padding: 4px;
    }

    /* Stack table cells vertically */
    .table-container thead {
        display: none;
    }

    .table-container tbody,
    .table-container tr {
        display: block;
        width: 100%;
    }

    .table-container tr {
        margin-bottom: 10px;
        border: 1px solid #ddd;
        padding: 10px;
    }

    .table-container td {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        padding: 5px;
    }

    .table-container td::before {
        content: attr(data-label);
        font-weight: bold;
        text-transform: capitalize;
    }

    /* Center align loader */
    .centered-loader {
        margin: 1rem auto;
        width: 50px !important;
        height: 50px !important;
    }

    /* Button styles */
    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .add-product-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

/* PRODUCT DASHBOARD PAGE STYLING END 480*/


/* PRODUCT DASHBOARD PAGE STYLING START 576 */

@media screen and (max-width: 576px) {

    /* Adjust container padding */
    .product-dashboard-container {
        padding: 0.5rem;
    }

    /* Reduce Typography size */
    .product-dashboard-container h4 {
        font-size: 1.2rem;
    }

    /* Make table responsive */
    .table-container {
        overflow-x: auto;
        display: block;
    }

    .table-container table {
        width: 100%;
        font-size: 12px;
    }

    .table-container th,
    .table-container td {
        padding: 4px;
    }

    /* Stack table cells vertically */
    .table-container thead {
        display: none;
    }

    .table-container tbody,
    .table-container tr {
        display: block;
        width: 100%;
    }

    .table-container tr {
        margin-bottom: 10px;
        border: 1px solid #ddd;
        padding: 10px;
    }

    .table-container td {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        padding: 5px;
    }

    .table-container td::before {
        content: attr(data-label);
        font-weight: bold;
        text-transform: capitalize;
    }

    /* Center align loader */
    .centered-loader {
        margin: 1rem auto;
        width: 50px !important;
        height: 50px !important;
    }

    /* Button styles */
    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .add-product-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

/* PRODUCT DASHBOARD PAGE STYLING END 576*/


/* PRODUCT ADDITION PAGE STYLING START 320 */

@media only screen and (max-width: 320px) {
    .cd-hero-form {
        padding: 10px;
    }

    .addition {
        padding: 5px;
    }

    .row-custom {
        flex-direction: column;
        align-items: center;
    }

    .content {
        padding: 10px;
        text-align: center;
    }

    .custom-label-login {
        font-size: 16px;
    }

    .formdata .row {
        flex-direction: column;
    }

    .formdata .col-md-6 {
        width: 100%;
        margin-bottom: 10px;
    }

    .file-input {
        width: 100%;
    }

    .primary-button {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    #form-submit {
        width: 100%;
    }

    .selected-file-text {
        font-size: 12px;
        text-align: center;
    }

    .form-custom {
        width: 100%;
        padding: 10px;
    }

    .login-custom {
        width: 100%;
    }

    .custom-button {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

/* PRODUCT ADDITION PAGE STYLING end 320 */


/* REGSTRATION DETAILS PAGE STYLING START 320*/


/* REGSTRATION DETAILS PAGE STYLING END 320*/